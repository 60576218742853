.job-assistant-component a {
  color: blue !important;
  text-decoration: underline !important;
}

.cs-message--outgoing .cs-message__content {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #edf2f7 !important;
}

.cs-message--incoming .cs-message__content {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #f7fafc !important;
}

.cs-message-input__content-editor-wrapper {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: #f7fafc !important;
}

.scrollbar-container .cs-message-input__content-editor {
  background-color: #f7fafc !important;
}

.cs-message-input .cs-message-input--disabled {
  background-color: #f7fafc !important;
}

.buttons-container {
  border-top: 1px solid #dfdfdf;
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 18px;
  justify-content: flex-start;
}

.buttons-container button {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  text-transform: capitalize;
  padding: 5px 25px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.025em;
  border-radius: 5px;
}

.buttons-container button:hover {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.disabled-buttons-container {
  pointer-events: none;
  opacity: 0.4;
  cursor: default;
}

.disabled-buttons-container button {
  cursor: not-allowed; /* Change cursor to indicate that the button is not clickable */
}

.action-button {
  color: #42b2e6;
}

.action-button-decline {
  color: #ce3939;
}
