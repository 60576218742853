.rheostat-container {
  display: flex;
  align-items: center;
}

.rheostat {
  height: 24px;
  position: relative;
  overflow: visible;
}

.rheostat-background {
  background: #dce0e0;
  height: 2px;
  position: relative;
  top: 14px;
  width: 100%;
}

.rheostat-values {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.6);
}

.rheostat--disabled .rheostat-progress {
  background-color: #edefed;
}

.rheostat--disabled .rheostat-handle {
  cursor: default;
}

.rheostat-progress {
  background-color: rgb(96, 165, 250);
  height: 4px;
  position: absolute;
  top: 13px;
}

.rheostat-handle {
  border: 1px solid rgb(96, 165, 250) !important;
  background: #fff !important;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 24px;
  margin-left: -12px;
  position: absolute;
  z-index: 2;
  width: 24px;
  font-size: 0;
}
