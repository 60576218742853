.customScrollBar html {
  overflow-y: scroll;
}

/* width */
.customScrollBar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.customScrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScrollBar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
