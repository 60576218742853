.numberCircle {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  font-size: 14px;
}

.numberCircle span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}
