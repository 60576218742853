.tooltip {
  display: inline-block;
  position: static;
}
.tooltip-content {
  visibility: hidden;
}

.tooltip:hover .tooltip-content {
  visibility: visible;
}
