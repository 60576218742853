ais-highlight-0000000000 {
  font-style: italic;
  color: rgba(59, 131, 246, 0.607); /* Example color */
}

.percent {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
}

.complete {
  font-size: 12px;
  line-height: 14px;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip-preview {
  border-bottom: 1px dotted black;
}

.tooltip-text {
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateX(-50%);
  padding: 15px;
  visibility: hidden;
  width: 360px;
  white-space: normal;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
