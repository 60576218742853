.header {
  display: grid;
  grid-template:
    "user user" auto
    "logo nav " 1fr / auto 1fr;
}

@media (min-width: 768px) {
  .header {
    grid-template:
      "logo user" auto
      "logo nav " 1fr / auto 1fr;
  }
}
