@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.ais-SearchBox-form {
  @apply my-4;
}

.ais-Stats {
  @apply my-2 text-right text-gray-300;
}

.ais-Panel .ais-Panel-header {
  @apply text-sm;
}

.ais-Panel {
  @apply p-4 border border-dashed border-gray-200;
}

[data-amplify-authenticator] {
  @apply flex justify-center items-center h-screen;
}

.amplify-button--primary {
  background-color: var(--amplify-components-button-primary-background-color);
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
